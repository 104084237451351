import {
  useGLTF,
  Environment,
  Float,
  PresentationControls,
  ContactShadows,
  Html,
  Text,
  useAnimations,
} from "@react-three/drei";
import "./style.css";
import { useEffect } from "react";


export default function Experience() {
  const computer = useGLTF(
    "./macbook.gltf"
  );

  const bello = useGLTF("./bello-v1.glb");

  const belloAnimation = useAnimations(bello.animations, bello.scene);

  useEffect(() => {
    console.log(belloAnimation );
    const action = belloAnimation.actions.leanAgainstWall;
    action.play();
  }, []);

  return (
    <>
      <Environment preset="city" />

      <color args={["#140733"]} attach="background" />

      <PresentationControls
        global
        rotation={[0.13, 0.1, 0]}
        polar={[-0.4, 0.2]}
        azimuth={[-1, 0.75]}
        config={{ mass: 2, tension: 200 }}
        snap={{ mass: 2, tension: 5 }}
      >
        <Float rotationIntensity={0.2}>
          {/* Light */}
          <rectAreaLight
            width={2.5}
            height={1.65}
            intensity={65}
            color={"#640793"}
            rotation={[0.5, Math.PI, 0]}
            position={[0, 0.55, -0.5]}
          />
          <rectAreaLight
            width={2.5}
            height={3.5}
            intensity={25}
            color={"#674a8a"}
            rotation={[.45, Math.PI, 0]}
            position={[4, 1.9, -0.85]}
          />
          {/* Model */}
          <primitive object={computer.scene} position={[0.7, -1.6, -0.0]}>
            {/* iFrame */}
            <Html
              transform
              wrapperClass="htmlScreen"
              distanceFactor={1.175}
              position={[0, 1.58, -1.4]}
              rotation-x={-0.256}
            >
              <iframe title='Portfolio' src="../portfolio/index.html"></iframe>
            </Html>

          </primitive>
         
          <Text
            font="./lovelo_black-regular.woff"
            fontSize={1}
            position={[3.9, 0.1, 0.1]}
            rotation-y={-1.25}
            color={"#fff8dc"}
            maxWidth={5}
            textAlign="center"
          >
            Current Projects
          </Text>
          <Text
            font="./lovelo_black-regular.woff"
            fontSize={0.1}
            color={"#f0006e"}
            position={[3.9, 1.2, 0.1]}
            rotation-y={-1.25}
            maxWidth={5}
            textAlign="center"
          >
            Use the laptop to check out my portfolio
          </Text>
          <Text
            font="./lovelo_black-regular.woff"
            fontSize={0.1}
            color={"#f0006e"}
            position={[0.5, 1.2, -1.6]}
            rotation-y={0}
            maxWidth={5}
            textAlign="center"
          >
            Scroll down for more
          </Text>
          <primitive
            object={bello.scene}
            position={[3.5, -1.6, -1.2]}
            rotation={[0.0, -1.2, 0.0]}
            scale={1.5}
          ></primitive>
        </Float>
      </PresentationControls>
      <ContactShadows position-y={-1.6} opacity={0.3} scale={10} blur={2.4} />
      
    </>
    
  );
  
}
