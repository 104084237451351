import "./style.css";
import ReactDOM from "react-dom/client";
import { Canvas } from "@react-three/fiber";
import Experience from "./Experience.jsx";
import App from "./App.jsx";
import { Suspense, useState } from "react";
import { Loader } from "@react-three/drei";
import LoadingScreen from "./LoadingScreen";

const root = ReactDOM.createRoot(document.querySelector("#root"));

root.render(
  <>
    <Canvas
      className="r3f"
      camera={{
        fov: 45,
        near: 0.1,
        far: 2000,
        position: [-3, 1.5, 4],
        dpr: [1, 2],
      }}
    >
      <Suspense fallback={null}>
        {/* <App /> */}

        <Experience />
      </Suspense>
    </Canvas>
    <Loader />
  </>
);
